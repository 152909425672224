import React from 'react'

function MyProgress() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="text-center top-input">
            <button><i className="fa-solid fa-magnifying-glass"></i></button
            ><input type="text" placeholder="search" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12 mt-3">
                <div className="row back-color">
                  <div className="col-md-3 col-sm-4 col-12">
                    <div className="con-img">
                      <img
                        src="/assets/images/concept-distance- 1.png"
                        className="new-img-two"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-8 col-12 simply">
                    <div>
                      <h2>Simply dummy text of the printing</h2>
                      <p>
                        It is a long established fact that a reader will be
                        distracted by the readable content of a page when
                        looking at its layout. The point of using Lorem Ipsum...
                      </p>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-12">
                    <div className="wrapper-box">
                      <div className="card">
                        <div className="circle">
                          <div className="bar"></div>
                          <div className="box"><span> </span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 new-pro mt-3">
                <div className="progress">
                  <svg
                    className="progress-circle"
                    width="200px"
                    height="200px"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="progress-circle-back-two"
                      cx="80"
                      cy="80"
                      r="74"
                    ></circle>
                    <circle
                      className="progress-circle-prog"
                      cx="80"
                      cy="80"
                      r="74"
                    ></circle>
                  </svg>
                  <div className="progress-text-1" data-progress="0">0%</div>
                </div>
                <div className="subject-prog">
                  <h2>Subject Progress</h2>
                  <p>Based on videos watched</p>
                  <p className="first-p">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum...
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="time-sp">
                  <h6>Time spent</h6>
                  <p>
                    Based on your activities in videos, practise, test and Quiz
                    for this subject
                  </p>
                </div>
                <div className="chrt">
                  <canvas id="myChart"></canvas>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-3">
                <ul
                  className="nav nav-pills mb-3 new-nav-pill nvpill"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-test-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-test"
                      type="button"
                      role="tab"
                      aria-controls="pills-test"
                      aria-selected="true"
                    >
                      Test
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-quiz-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-quiz"
                      type="button"
                      role="tab"
                      aria-controls="pills-quiz"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Quiz
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-schedule-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-schedule"
                      type="button"
                      role="tab"
                      aria-controls="pills-schedule"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Scheduled
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-livetest-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-livetest"
                      type="button"
                      role="tab"
                      aria-controls="pills-livetest"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Live Test
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-test"
                    role="tabpanel"
                    aria-labelledby="pills-test-tab"
                    tabIndex="0"
                  >
                    <div className="row mt-3">
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="img-under-logo">
                          <div className="attempt1-img">
                            <img
                              src="/assets/images/task-list-1.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <button>
                            Test Attempted <span className="fs-accuracy"> 10</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="img-under-logo">
                          <div className="attempt2-img">
                            <img
                              src="/assets/images/rt.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <button>
                            Correct Answers <span className="fs-accuracy"> 40</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="img-under-logo">
                          <div className="attempt3-img">
                            <img
                              src="/assets/images/char.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <button>
                            Accuracy <span className="fs-accuracy"> 15%</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-3 col-sm-6 col-12">
                        <div className="img-under-logo">
                          <div className="attempt4-img">
                            <img
                              src="/assets/images/alrm.png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <button>
                            Avg.Response Time
                            <span className="fs-accuracy"> 20 mint</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="skill-ana">
                              <h6>Skills Analysis</h6>
                              <p>
                                Based on your activities in videos, practise,
                                test and Quiz for this subject
                              </p>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <ul className="app-based-flex">
                              <li>
                                <div className="pro-circle">
                                  <div className="box">
                                    <div
                                      className="chart"
                                      data-percent="73"
                                      data-scale-color="#ffb400"
                                    >
                                      73%
                                    </div>
                                  </div>
                                  <div className="skill-img">
                                    <img src="/assets/images/sk1.png" alt="" />
                                  </div>
                                  <h6>Skill Based</h6>
                                </div>
                              </li>
                              <li>
                                <div className="pro-circle">
                                  <div className="box">
                                    <div
                                      className="chart"
                                      data-percent="73"
                                      data-scale-color="#ffb400"
                                    >
                                      73%
                                    </div>
                                  </div>
                                  <div className="skill-img">
                                    <img
                                      src="/assets/images/sk2.png"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <h6>Application Based</h6>
                                </div>
                              </li>
                              <li>
                                <div className="pro-circle">
                                  <div className="box">
                                    <div
                                      className="chart"
                                      data-percent="73"
                                      data-scale-color="#ffb400"
                                    >
                                      73%
                                    </div>
                                  </div>
                                  <div className="skill-img">
                                    <img
                                      src="/assets/images/sk3.png"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <h6>Memorising Ability</h6>
                                </div>
                              </li>
                              <li>
                                <div className="pro-circle">
                                  <div className="box">
                                    <div
                                      className="chart"
                                      data-percent="73"
                                      data-scale-color="#ffb400"
                                    >
                                      73%
                                    </div>
                                  </div>
                                  <div className="skill-img">
                                    <img
                                      src="/assets/images/sk4.png"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <h6>Understanding</h6>
                                </div>
                              </li>
                              <li>
                                <div className="pro-circle">
                                  <div className="box">
                                    <div
                                      className="chart"
                                      data-percent="73"
                                      data-scale-color="#ffb400"
                                    >
                                      73%
                                    </div>
                                  </div>
                                  <div className="skill-img">
                                    <img
                                      src="/assets/images/sk5.png"
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </div>
                                  <h6>Knowledge</h6>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-12">
                            <div className="skill-ana">
                              <h6>Key areas to improve</h6>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="intro-box">
                                  <h5>Introduction</h5>
                                  <p>Triangles</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="intro-box">
                                  <h5>Similar Figures</h5>
                                  <p>Triangles</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="intro-box">
                                  <h5>Similarity of Triangles</h5>
                                  <p>Triangles</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-quiz"
                    role="tabpanel"
                    aria-labelledby="pills-quiz-tab"
                    tabIndex="0"
                  >
                    Quiz
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-schedule"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    tabIndex="0"
                  >
                    Schedule
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-livetest"
                    role="tabpanel"
                    aria-labelledby="pills-livetest-tab"
                    tabIndex="0"
                  >
                    Live Test
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default MyProgress