import AcceleratorHeader from "../components/accelerator/header"
import EduhelperHeader from "../components/eduhelper/header"
import Footer from "../components/footer"
import Header from "../components/header"

export const LayoutOne = (props) => {
    return(
        <>
            <Header/>
            <div className="navbar-head-top-pad">
                {props.children}
            </div>
            <Footer/>
        </>
    )
}


export const LayoutTwo = (props) => {
    return(
        <>
            {props.children}
        </>
    )
}

export const EduhelperLayout = (props) => {
    return (
        <>  
            <EduhelperHeader />
            {props.children}
        </>
    )
}


export const AcceleratorBatchLayout = (props) => {
    return (
        <div className="accelerator-batch">
            <AcceleratorHeader />
            {props.children}
            <Footer/>
        </div>
    )
}