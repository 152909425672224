const initialState = {
    type:"",
    data: [],
    message: ""
  }
  
  export function  subscriptionReducer(state =initialState, action){
    let obj;
    let tmp = [];
     obj = {...state};
    // eslint-disable-next-line default-case
    switch (action.type) {
      case "RESET_SUBSCRIPTION":
        return  {type:"",data:[],message:""}
      case "SUBSCRIPTION_REQUEST":
        return {...state,type:"request"}
      case "SUBSCRIPTION_SUCCESS":
        return {...state, type: 'success', data: action.data}
      case "SUBSCRIPTION_ERROR":
        return {...state, type: 'error', message: action.message}
      case "SUBSCRIPTION_TYPE_RESET":
        return {...state, type: "", message: ""}
  
      default:
        return state
    }
    return state;
  }
  