import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import OtpModal from "../modals/otp";
import CallModal from "../modals/call";
import GoalModal from "../modals/goal";
import SetPasswordModal from "../modals/setPwd";
import PasswordModal from "../modals/password";
import LoginModal from "../modals/login";



import { ToastContainer, toast } from "react-toastify";

import { connect } from "react-redux";
import {
  userLoginWithPhone,
  setUserPassword,
  verifyPOtp,
  loginWithPassword,
  updateUserInfo,
  setBoardId,
  setStandardId,
  setUserInfo,
  forgotUserPassword
} from "../../services/actions";

import axios from "axios";

function Header(props) {
  const { boards } = props;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mType, setMType] = useState("login");

  const [hoveredBoardId, setHoveredBoardId] = useState("")
  const [hoveredStandardId, setHoveredStandardId] = useState("")

  const [userPNo, setUserPNo] = useState("");

  const [apiCalled, setApiCalled] = useState(false);

  // const [boards, setBoards] = useState([])
  const [avatars, setAvatars] = useState([]);

  const [fromSubscription,setFromSubscription] = useState(false)

  const { user } = props;

  const [userProfileData, setUserProfileData] = useState({
    name: "",
    username: "",
    standard_id: "",
  });

  const logoutHandler = () => {
    window.location.reload();
    localStorage.clear("user")
  };

  const modelOpenHandler = async (t, from_subscription) => {
    await setOpen(true);
    await setMType(t);

    if(from_subscription){
      setFromSubscription(from_subscription)
    }

    document.getElementById(`${t}-btn`).click();
  };

  const modalCloseHandler = () => setOpen(false);

  const loginUser = (pNo) => {
    setUserPNo(pNo);

    // if(pNo.length != 10){
    //     setLoginError("Please enter a correct phone number")
    //     return
    // }

    let data = {
      xcxzbczcxnzmbxbzcxkzxcj: pNo,
      vcvcvcxcvbzncvvcznxcxxcz: "eduaid",
    };

    props.userLoginWithPhone(data, async (res) => {
      if (res.type == "error") {
        // toast.error(res.message);
      } else {
        // toast.success(res.message);

        let type;

        if (res.data.new_user || !res.data.password) {
          type = "otp";
          await setMType(type);

          document.getElementById(`${type}-btn`).click();
        } else {
          type = "pwd";
          await setMType(type);

          document.getElementById(`${type}-btn`).click();
        }
        setOpen(false);
      }
    });
  };

  const setPasswordHandler = (pwd, cnfPassword) => {
    if (pwd !== cnfPassword) {
      // toast.error("Passwords doesnt match");
      return;
    }
    let type;

    let data = {
      mobile: userPNo,
      password: pwd,
    };
    props.setUserPassword(data, (res) => {
      if (res.type == "success") {
        type = "goal";
        // toast.success(res.message);

        document.getElementById(`${type}-btn`).click();
      } else {
        // toast.error(res.message);
      }
    });
  };

  const verifyOtp = (otp) => {
    let data = {
      mobile: userPNo,
      otp,
    };

    props.verifyPOtp(data, (res) => {
      let type;
      if (res.type == "error") {
        // toast.error(res.message);
      } else {
        type = "setPwd";
        // toast.success(res.message);

        document.getElementById(`${type}-btn`).click();
      }
    });
  };

  const loginWPwd = (passwd) => {
    let data = {
      mobile: userPNo,
      password: passwd,
    };

    let type;

    props.loginWithPassword(data, async (res) => {
      if (res.type == "error") {
        // toast.error(res.message);
      } else {

        if(fromSubscription){
          navigate('/subscription')
        }

        if(res.alreadyUser){
          // toast.success("User logged in successfully");
          document.getElementById("close-pwd-btn").click();
        }else{
            type = "goal";
            // toast.success(res.message);
  
            document.getElementById(`${type}-btn`).click();
        }
        // if (userObj) {
        // await props.setUserInfo(userObj)
        // // toast.success("User logged in successfully");
        // document.getElementById("close-pwd-btn").click();

      
        // } 
        
      }
    });
  };

  const setGoalHandler = async (s_id) => {
    setApiCalled(true);
    setUserProfileData({ ...userProfileData, standard_id: s_id });
    let existing_data = JSON.parse(localStorage.getItem("user"))
    existing_data = {...existing_data, standard_id: s_id}

    localStorage.setItem("user", JSON.stringify(existing_data));

    let avatarsData = await axios.get("/api/v3/s/avatars/");
    setAvatars(avatarsData.data.response.data.avatars);

    setApiCalled(false);


    let type = "call";
    document.getElementById(`${type}-btn`).click();
  };

  const setUserInfo = (u_name) => {
    let existing_data = { ...userProfileData };
    existing_data["name"] = u_name;
    existing_data["username"] = u_name.toLowerCase();

    setUserProfileData(existing_data);

    localStorage.setItem("user", JSON.stringify(existing_data));

    props.updateUserInfo(existing_data, (res) => {
      if (res.type == "error") {
        // toast.error(res.message);
      } else {
        // toast.success(res.message);

        let type = "close";
        document.getElementById(`close-btn`).click();
      }
    });
  };

  const selectionHandler = (type, id) => {
    if(type == "board"){
        setHoveredBoardId(id)
        props.setBoardId(id)
        document.getElementById("multiDropdown").classList.add("show")
    }else{
        setHoveredStandardId(id)
        props.setStandardId(id)
        document.getElementById("multiDropdown").classList.remove("show")
        navigate('/subscription')
    }
  }


  useEffect(() => {
    if(boards.selectedBoardId != ""){
        setHoveredBoardId(boards.selectedBoardId)
    }
    if(boards.selectedStandardId){
        setHoveredStandardId(boards.selectedStandardId)
    }
    }, [boards.selectedBoardId, boards.selectedStandardId])


  const forgotPassword = () => {
    props.forgotUserPassword(userPNo, async(res) => {
      if(res.type == "error"){
        // toast.error(res.message)
      }else{
        let type = "otp";
        await setMType(type);

        document.getElementById(`${type}-btn`).click();
      }
    })
  }


  return (
    <>  
      <div className="nav-mrgn">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <a className="navbar-brand logo-img" href="https://eduaid.co.in">
              <img
                src="assets/images/eduaid-logo-main.png"
                className="img-fluid"
                alt=""
              />
            </a>

            {/* <div style={{display:"flex", justifyContent: "flex-end"}}>
              {user.login &&
                <>
                  <div className="dropdown">
                  <button
                      className="btn btn-primary drp-downbtn dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      data-bs-auto-close="outside"
                      aria-expanded="false"
                  >
                      Choose Board
                  </button>
                  <ul
                      className={`dropdown-menu`}
                      aria-labelledby="dropdownMenuButton1"
                  >
                      {boards.data.map((b_data, index) => {
                      return (
                          <li id="dropdownMenuButton2" aria-expanded="false" data-bs-auto-close="outside" key={index} onClick={() => {
                              selectionHandler("board", b_data.id)
                          }}>
                          <span className={`dropdown-item ${hoveredBoardId == b_data.id ? "active" : ""}`} href="#">
                              {b_data.name}
                          </span>
                          </li>
                      );
                      })}
                  </ul>
                      <ul aria-labelledby="dropdownMenuButton2" style={{marginLeft: "10rem"}} id="multiDropdown" className={`dropdown-menu`}>
                      {hoveredBoardId != "" &&
                          boards.data.filter((item) => item.id == hoveredBoardId)[0]?.standards.map((standard_data, index) => {
                              return(
                                  <li key={index} onClick={() => {
                                      selectionHandler("standard", standard_data.id)
                                  }}>
                                      <span className={`dropdown-item ${hoveredStandardId == standard_data.id ? "active" : ""}`} href="#">
                                          {standard_data.name_slug}
                                      </span>
                                  </li>
                              )
                          })
                      }
                      </ul>
                  </div>
                  {false &&
                    <div className="dropdown">
                    <button
                        className="btn btn-primary drp-downbtn dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton2"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        Study Material
                    </button>
                    <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton2"
                    >
                        <li>
                        <span className="dropdown-item" href="javascript:void(0)">
                            Action
                        </span>
                        </li>
                        <li>
                        <span className="dropdown-item" href="javascript:void(0)">
                            Another action
                        </span>
                        </li>
                        <li>
                        <span className="dropdown-item" href="javascript:void(0)">
                            Something else here
                        </span>
                        </li>
                    </ul>
                    </div>
                  }
                </>
              }
            </div> */}
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav text-center ms-auto mb-2 mb-lg-0">
                {user.data.mobile_no == '8955569278' &&
                  <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/admin">
                      Admin
                    </Link>
                  </li>
                }
                <li className="nav-item">
                  <Link className="nav-link active" aria-current="page" to="/">
                    <span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                      Home
                    </span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about-us">
                  <span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                    About us
                    </span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/become-a-teacher">
                  <span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                    Become a teacher
                    </span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  {user.login ? (
                    <Link className="nav-link" to="/subscription">
                      <span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                      Subscriptions
                      </span>
                    </Link>
                  ) : (
                    <Link
                      className="nav-link"
                      onClick={() => modelOpenHandler("login", true)}
                    >
                      <span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                      Subscriptions
                      </span>
                    </Link>
                  )}
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/contact-us">
                  <span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                    Contact us
                    </span>
                  </Link>
                </li>
              </ul>
              {/* <div className="d-flex tx-centr">
                {user.login ? (
                  <button
                    className="btn btn-outline-success btnlogin"
                    type="button"
                    onClick={logoutHandler}
                  >
                    Logout
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-success btnlogin"
                    type="button"
                    onClick={() => modelOpenHandler("login", false)}
                  >
                    Login
                  </button>
                )}
              </div> */}
            </div>
          </div>
        </nav>
      </div>

      <ToastContainer />

      <button
        id={"login-btn"}
        style={{ display: "none" }}
        className="btn btn-outline-success btnlogin"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        type="button"
      >
        Login
      </button>
      <button
        id={"otp-btn"}
        style={{ display: "none" }}
        className="btn btn-danger btnotp"
        data-bs-toggle="modal"
        data-bs-target="#otpmodal"
        data-bs-dismiss="modal"
      >
        Continue
      </button>
      <button
        id={"pwd-btn"}
        style={{ display: "none" }}
        className="btn btn-danger btnotp1"
        data-bs-toggle="modal"
        data-bs-target="#pwdmodal"
        data-bs-dismiss="modal"
      >
        Continue
      </button>
      <button
        id={"setPwd-btn"}
        style={{ display: "none" }}
        className="btn btn-danger btnotp1"
        data-bs-toggle="modal"
        data-bs-target="#setpwdmodal"
        data-bs-dismiss="modal"
      >
        Continue
      </button>
      <button
        id={"goal-btn"}
        style={{ display: "none" }}
        className="btn btn-danger btnotp1"
        data-bs-toggle="modal"
        data-bs-target="#goalmodal"
        data-bs-dismiss="modal"
      >
        Continue
      </button>

      <button
        id={"call-btn"}
        style={{ display: "none" }}
        className="btn btn-danger rad-btn"
        data-bs-toggle="modal"
        data-bs-target="#callingmodal"
        data-bs-dismiss="modal"
      >
        Continue
      </button>

      <LoginModal
        open={open && mType == "login"}
        handleClose={modalCloseHandler}
        loginUser={loginUser}
        apiCalled={apiCalled}
      />
      <OtpModal verifyOtp={verifyOtp} apiCalled={apiCalled} userPNo={userPNo} />
      <PasswordModal loginWPwd={loginWPwd} forgotPassword={forgotPassword}/>
      <SetPasswordModal
        setPasswordHandler={setPasswordHandler}
        apiCalled={apiCalled}
      />
      <GoalModal setGoalHandler={setGoalHandler} apiCalled={apiCalled} />
      <CallModal avatars={avatars} setUserInfo={setUserInfo} />
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  boards: state.boards,
});

const mapDispatchToProps = {
  userLoginWithPhone,
  verifyPOtp,
  setUserPassword,
  loginWithPassword,
  updateUserInfo,
  setBoardId,
  setStandardId,
  setUserInfo,
  forgotUserPassword
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
