import React from 'react'

function StoreComp() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="text-center top-input mb-3">
            <button><i className="fa-solid fa-magnifying-glass"></i></button
            ><input type="text" placeholder="search" />
          </div>
          <div className="container-fluid new-div">
            <div className="row">
              <div className="col-md-8 col-sm-8">
                <div className="all-h1-p">
                  <h1>You Can Now Try Our Courses</h1>
                  <p className="text-white f-famaly">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <button>Try Now</button>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="model-img">
                  <img src="/assets/images/model-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-3">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-pills mb-3 new-nav-pill nvpill"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-vip-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-vip"
                      type="button"
                      role="tab"
                      aria-controls="pills-vip"
                      aria-selected="true"
                    >
                      VIP
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-subject-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-subject"
                      type="button"
                      role="tab"
                      aria-controls="pills-subject"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Subject
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-addons-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-addons"
                      type="button"
                      role="tab"
                      aria-controls="pills-addons"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Add Ons
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-vip"
                    role="tabpanel"
                    aria-labelledby="pills-vip-tab"
                    tabIndex="0"
                  >
                    VIP
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-subject"
                    role="tabpanel"
                    aria-labelledby="pills-subject-tab"
                    tabIndex="0"
                  >
                    Subject
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-addons"
                    role="tabpanel"
                    aria-labelledby="pills-addons-tab"
                    tabIndex="0"
                  >
                    Add Ons
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-2">
            <div className="row">
              <div className="col-md-12">
                <div className="my-4">
                  <div className="cascade-slider_container" id="cascade-slider">
                    <div className="cascade-slider_slides">
                      <div className="cascade-slider_item">
                        <div className="card-sldr align card-bg-img">
                          <div className="row">
                            <div className="col-md-6 col-6">
                              <div className="crown-box">
                                <img
                                  src="assets/images/crown-blue.png"
                                  alt=""
                                />
                                <span>GRAND MASTER X C</span>
                              </div>
                              <div className="grand-box">
                                <p>
                                  Be the grand master with every features
                                  unlocked !!
                                </p>
                                <h6>Mentorship...</h6>
                                <a href="javascript:void(0)" className=""
                                  >Read More...</a
                                >
                              </div>
                            </div>
                            <div className="col-md-6 col-6">
                              <div className="recommend">
                                <h5>RECOMMENDED</h5>

                                <div className="rs-buy">
                                  <p>₹ 10,000/-</p>
                                  <a className="btn btn-danger buy-now-btn" href=""
                                    >Buy Now</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cascade-slider_item">
                        <div className="card-sldr align card-bg-img2">
                          <div className="row">
                            <div className="col-md-6 col-6">
                              <div className="crown-box">
                                <img
                                  src="assets/images/crown-yellow.png"
                                  alt=""
                                />
                                <span>GRAND MASTER X C</span>
                              </div>
                              <div className="grand-box">
                                <p>
                                  Be the grand master with every features
                                  unlocked !!
                                </p>
                                <h6>Mentorship...</h6>
                                <a href="javascript:void(0)" className=""
                                  >Read More...</a
                                >
                              </div>
                            </div>
                            <div className="col-md-6 col-6">
                              <div className="recommend">
                                <h5>RECOMMENDED</h5>

                                <div className="rs-buy">
                                  <p>₹ 10,000/-</p>
                                  <a className="btn btn-danger buy-now-btn" href=""
                                    >Buy Now</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="cascade-slider_item">
                        <div className="card-sldr align card-bg-img3">
                          <div className="row">
                            <div className="col-md-6 col-6">
                              <div className="crown-box">
                                <img src="assets/images/crown-red.png" alt="" />
                                <span>GRAND MASTER X C</span>
                              </div>
                              <div className="grand-box">
                                <p>
                                  Be the grand master with every features
                                  unlocked !!
                                </p>
                                <h6>Mentorship...</h6>
                                <a href="javascript:void(0)" className=""
                                  >Read More...</a
                                >
                              </div>
                            </div>
                            <div className="col-md-6 col-6">
                              <div className="recommend">
                                <h5>RECOMMENDED</h5>

                                <div className="rs-buy">
                                  <p>₹ 10,000/-</p>
                                  <a className="btn btn-danger buy-now-btn" href=""
                                    >Buy Now</a
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <span
                      className="cascade-slider_arrow cascade-slider_arrow-left"
                      data-action="prev"
                      ><img
                        src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/chvron-left.svg"
                        type="image/svg+xml"
                    /></span>
                    <span
                      className="cascade-slider_arrow cascade-slider_arrow-right"
                      data-action="next"
                      ><img
                        src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/chvron-right.svg"
                        type="image/svg+xml"
                    /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  )
}

export default StoreComp