import axios from "axios"

export const getBatchesData = async (cb) => {
    let batchesData = await axios.get('/api/v3/s/get-batches')

    if(batchesData.data.status == "success"){
        cb({type: batchesData.data.status, message: batchesData.data.response.statusDescription, data: batchesData.data.response.data.batch})
    }else{
        cb({type: batchesData.data.status, message: batchesData.data.response.statusDescription})
    }
}


export const getStudentBatchesData = async (cb) => {
    let studentBatchesData = await axios.get('/api/v3/s/get-student-batch')

    if(studentBatchesData.data.status == "success"){
        cb({type: studentBatchesData.data.status, message: studentBatchesData.data.response.statusDescription, data: studentBatchesData.data.response.data.batch})
    }else{
        cb({type: studentBatchesData.data.status, message: studentBatchesData.data.response.statusDescription})
    }
}