import React, { useState } from 'react'
import { connect } from 'react-redux';

function OtpModal(props) {
    const {user} = props;
    const {verifyOtp,apiCalled,userPNo} = props

    const [dig1, setDig1] = useState('')
    const [dig2, setDig2] = useState('')
    const [dig3, setDig3] = useState('')
    const [dig4, setDig4] = useState('')

    return (
        <>
            <div className="modal fade" id="otpmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-ku">
                    <div className="modal-content">
                        <div className="modal-body m-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 pdg-0">
                                        <div className="otp-img">
                                            <img src="assets/images/otpimg.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-logo">
                                                    <div className="logo-otp">
                                                        <img src="assets/images/logoedu.png" className="img-fluid" alt="" />
                                                    </div>
                                                    <div className="help-box">
                                                        <a href="">
                                                            <img src="assets/images/ques.png" alt="" /> Help</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="codes1">Enter the code sent to your phone</p>
                                                    <p className="mbl-no">
                                                        (+91) {userPNo}
                                                        <a href="javascript:void(0)"><i className="fas fa-edit"></i></a>
                                                    </p>
                                                    <div className="otpinputfield">
                                                        <input type="text" autoFocus id="digit-1" name="digit-1" maxLength="1" value={dig1} onChange={(e) => {
                                                            if(e.target.value.length == 1){
                                                                document.getElementById('digit-2').focus()
                                                            }
                                                            setDig1(e.target.value)
                                                        }}/>
                                                        <input type="text" id="digit-2" name="digit-2" maxLength="1" value={dig2} onChange={(e) => {
                                                            if(e.target.value.length == 1){
                                                                document.getElementById('digit-3').focus()
                                                            }else{
                                                                document.getElementById('digit-1').focus()
                                                            }
                                                            setDig2(e.target.value)
                                                        }} />
                                                        <input type="text" id="digit-3" name="digit-3" maxLength="1" value={dig3} onChange={(e) => {
                                                            if(e.target.value.length == 1){
                                                                document.getElementById('digit-4').focus()
                                                            }else{
                                                                document.getElementById('digit-2').focus()
                                                            }
                                                            setDig3(e.target.value)
                                                        }} />
                                                        <input type="text" id="digit-4" name="digit-4" maxLength="1" value={dig4} onChange={(e) => {
                                                            if(e.target.value.length == 1){
                                                                let otp = dig1 + dig2 + dig3 + e.target.value
                                                                verifyOtp(otp)
                                                            }else{
                                                                document.getElementById('digit-3').focus()
                                                            }
                                                            // if(e.target.value.length == 1){
                                                            //     document.getElementById('digit-2')
                                                            // }
                                                            setDig4(e.target.value)
                                                        }} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 text-center">
                                                    <button className={`btn btn-danger btnotp1 ${( apiCalled || user.type == 'request') ? "btn-req" : ""}`} onClick={() => {
                                                        let otp = dig1 + dig2 + dig3 + dig4
                                                        verifyOtp(otp)
                                                    }}>
                                                        {user.type =='request' ? "Contining..." : "Continue" }
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                {false &&
                                                <div className="resend-otp">
                                                    <p>Resend OTP in</p>
                                                    <div id="app"></div>
                                                </div>
                                                }
                                            </div>
                                            <div className="col-md-12">
                                                <div className="privacy1">
                                                    <p>
                                                        By continuing you consent to share your information
                                                        with EduAid & agree to our <span>T&C</span> and
                                                        <span>Privacy Policy</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpModal)