import React from 'react'
import { connect } from 'react-redux';

function AskAAdoubt(props) {
  const {admin} = props

  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center top-input">
                  <button><i className="fa-solid fa-magnifying-glass"></i></button><input type="text" placeholder="Search" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="cont mb-3 mt-3">
                  <h1>Subjects</h1>
                </div>
              </div>
              <div className="col-md-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                  style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", overflowX: "auto"}}
                >
                  {admin.subjects.data.map((item, index) => {
                    return(
                      <li className="nav-item math-dbt" role="presentation">
                        <button
                          className="nav-link nv-link active"
                          id="pills-mathdoubt-tab"
                          data-bs-toggle="pill"
                          data-bs-target={`#pills-${item.name}doubt`}
                          type="button"
                          role="tab"
                          aria-controls="pills-mathdoubt"
                          aria-selected="true"
                          fdprocessedid="y473dv"
                        >
                          {item.name}
                        </button>
                      </li>
                    )
                  })}
                  {/* <li className="nav-item bio-dbt" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-biodoubt-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-biodoubt"
                      type="button"
                      role="tab"
                      aria-controls="pills-biodoubt"
                      aria-selected="false"
                      fdprocessedid="26g2ud"
                      tabIndex="-1"
                    >
                      Bio
                    </button>
                  </li>
                  <li className="nav-item phy-dbt" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-phydoubt-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-phydoubt"
                      type="button"
                      role="tab"
                      aria-controls="pills-phydoubt"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Physics
                    </button>
                  </li>
                  <li className="nav-item eng-dbt" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-engdoubt-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-engdoubt"
                      type="button"
                      role="tab"
                      aria-controls="pills-eng"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item che-dbt" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-chedoubt-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-chedoubt"
                      type="button"
                      role="tab"
                      aria-controls="pills-chedoubt"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Chemistry
                    </button>
                  </li>
                  <li className="nav-item poet-dbt" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-poetdoubt-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-poetdoubt"
                      type="button"
                      role="tab"
                      aria-controls="pills-poetdoubt"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Poetry
                    </button>
                  </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="pills-mathdoubt"
                    role="tabpanel"
                    aria-labelledby="pills-mathdoubt-tab"
                    tabIndex="0"
                  >
                    <div className="container">
                      <div className="row row-two">
                        <div className="col-md-12">
                          <div className="d-flex mt-3">
                            <img
                              src="./assets/images/avatar-profile-one.png"
                              alt="John Doe"
                              className="rounded-circle img-fluid avt-img"
                            />
                            <div className="f-famaly">
                              <p className="avt-p">
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking its layout.
                              </p>
                            </div>
                          </div>
                          <div className="d-flex mt-3">
                            <div className="f-famaly">
                              <p className="avt-p">
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking its layout.
                              </p>
                            </div>
                            <img
                              src="./assets/images/avatar-profile-two.png"
                              alt="John Doe"
                              className="rounded-circle img-fluid avt-img"
                            />
                          </div>
                          <div className="date-fs">
                            <span className=""> 22 Sep. 2022 </span><span> 10:39 AM</span>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="d-flex mt-3">
                            <img
                              src="./assets/images/avatar-profile-one.png"
                              alt="John Doe"
                              className="rounded-circle img-fluid avt-img"
                            />
                            <div className="f-famaly">
                              <p className="avt-p">
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking its layout.
                              </p>
                            </div>
                          </div>
                          <div className="d-flex mt-3">
                            <div className="f-famaly">
                              <p className="avt-p">
                                It is a long established fact that a reader will
                                be distracted by the readable content of a page
                                when looking its layout.
                              </p>
                            </div>
                            <img
                              src="./assets/images/avatar-profile-two.png"
                              alt="John Doe"
                              className="rounded-circle img-fluid avt-img"
                            />
                          </div>
                          <div className="date-fs">
                            <span className=""> 24 Sep. 2022 </span><span> 10:39 AM</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-biodoubt"
                    role="tabpanel"
                    aria-labelledby="pills-biodoubt-tab"
                    tabIndex="0"
                  >
                    Bio Doubt
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-phydoubt"
                    role="tabpanel"
                    aria-labelledby="pills-phydoubt-tab"
                    tabIndex="0"
                  >
                    Physics Doubt
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-engdoubt"
                    role="tabpanel"
                    aria-labelledby="pills-engdoubt-tab"
                    tabIndex="0"
                  >
                    English Doubt
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-chedoubt"
                    role="tabpanel"
                    aria-labelledby="pills-chedoubt-tab"
                    tabIndex="0"
                  >
                    Chemistry Doubt
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-poetdoubt"
                    role="tabpanel"
                    aria-labelledby="pills-poetoubt-tab"
                    tabIndex="0"
                  >
                    Poetry Doubt
                  </div>
                </div>

                <div className='mt-3'>
                  <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="Ask your doubt" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
                    <button class="input-group-text btn btn-primary" id="basic-addon2">send</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

const mapStateToProps = (state) => ({
  admin: state.admin,
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AskAAdoubt);
