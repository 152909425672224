import React from 'react'

function FeedsComp() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-pills mb-3 new-nav-pill nvpill"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-blogs-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-blogs"
                      type="button"
                      role="tab"
                      aria-controls="pills-blogs"
                      aria-selected="true"
                    >
                      Blogs
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-videos-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-videos"
                      type="button"
                      role="tab"
                      aria-controls="pills-videos"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      Videos
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-news-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-news"
                      type="button"
                      role="tab"
                      aria-controls="pills-news"
                      aria-selected="false"
                      tabIndex="-1"
                    >
                      News
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-blogs"
                    role="tabpanel"
                    aria-labelledby="pills-blogs-tab"
                    tabIndex="0"
                  >
                    <div className="container">
                      <div className="row gttrfeed">
                        <div className="col-md-4 col-6">
                          <div className="card card-feed">
                            <img
                              src="assets/images/marketing-banner.png"
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="card-body crd-bdy-dummy">
                              <p className="card-text crddummy">
                                Simply dummy text Some quick example text to
                                build on the card title and make up the bulk of
                                the card's content.
                              </p>
                              <div className="heart-flex">
                                <div className="twelv">
                                  <i className="bx bx-heart"></i> <span>12</span>
                                </div>
                                <div className="shareicon">
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i className="fa fa-share"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="card card-feed">
                            <img
                              src="assets/images/marketing-banner.png"
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="card-body crd-bdy-dummy">
                              <p className="card-text crddummy">
                                Simply dummy text Some quick example text to
                                build on the card title and make up the bulk of
                                the card's content.
                              </p>
                              <div className="heart-flex">
                                <div className="twelv">
                                  <i className="bx bx-heart"></i> <span>12</span>
                                </div>
                                <div className="shareicon">
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i className="fa fa-share"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="card card-feed">
                            <img
                              src="assets/images/marketing-banner.png"
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="card-body crd-bdy-dummy">
                              <p className="card-text crddummy">
                                Simply dummy text Some quick example text to
                                build on the card title and make up the bulk of
                                the card's content.
                              </p>
                              <div className="heart-flex">
                                <div className="twelv">
                                  <i className="bx bx-heart"></i> <span>12</span>
                                </div>
                                <div className="shareicon">
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i className="fa fa-share"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="card card-feed">
                            <img
                              src="assets/images/marketing-banner.png"
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="card-body crd-bdy-dummy">
                              <p className="card-text crddummy">
                                Simply dummy text Some quick example text to
                                build on the card title and make up the bulk of
                                the card's content.
                              </p>
                              <div className="heart-flex">
                                <div className="twelv">
                                  <i className="bx bx-heart"></i> <span>12</span>
                                </div>
                                <div className="shareicon">
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i className="fa fa-share"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="card card-feed">
                            <img
                              src="assets/images/marketing-banner.png"
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="card-body crd-bdy-dummy">
                              <p className="card-text crddummy">
                                Simply dummy text Some quick example text to
                                build on the card title and make up the bulk of
                                the card's content.
                              </p>
                              <div className="heart-flex">
                                <div className="twelv">
                                  <i className="bx bx-heart"></i> <span>12</span>
                                </div>
                                <div className="shareicon">
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i className="fa fa-share"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-6">
                          <div className="card card-feed">
                            <img
                              src="assets/images/marketing-banner.png"
                              className="img-fluid"
                              alt="..."
                            />
                            <div className="card-body crd-bdy-dummy">
                              <p className="card-text crddummy">
                                Simply dummy text Some quick example text to
                                build on the card title and make up the bulk of
                                the card's content.
                              </p>
                              <div className="heart-flex">
                                <div className="twelv">
                                  <i className="bx bx-heart"></i> <span>12</span>
                                </div>
                                <div className="shareicon">
                                  <div>
                                    <a href="javascript:void(0)">
                                      <i className="fa fa-share"></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-videos"
                    role="tabpanel"
                    aria-labelledby="pills-videos-tab"
                    tabIndex="0"
                  >
                    Videos
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-news"
                    role="tabpanel"
                    aria-labelledby="pills-news-tab"
                    tabIndex="0"
                  >
                    News
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  )
}

export default FeedsComp