import React from 'react'

function PyqComp() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="cont mb-3 mt-3">
              <h1>Subject</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item item-hover math-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-mathspyq-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-mathspyq"
                      type="button"
                      role="tab"
                      aria-controls="pills-mathspyq"
                      aria-selected="true"
                    >
                      Maths
                    </button>
                  </li>
                  <li className="nav-item item-hover bio-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-biopyq-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-biopyq"
                      type="button"
                      role="tab"
                      aria-controls="pills-biopyq"
                      aria-selected="false"
                    >
                      Bio
                    </button>
                  </li>
                  <li className="nav-item item-hover phy-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-phypyq-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-phypyq"
                      type="button"
                      role="tab"
                      aria-controls="pills-phypyq"
                      aria-selected="false"
                    >
                      Physics
                    </button>
                  </li>
                  <li className="nav-item item-hover eng-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-engpyq-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-engpyq"
                      type="button"
                      role="tab"
                      aria-controls="pills-engpyq"
                      aria-selected="false"
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item item-hover che-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-chepyq-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-chepyq"
                      type="button"
                      role="tab"
                      aria-controls="pills-chepyq"
                      aria-selected="false"
                    >
                      Chemistry
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-mathspyq"
                    role="tabpanel"
                    aria-labelledby="pills-mathspyq-tab"
                    tabIndex="0"
                  >
                    <div className="row row-two">
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (1).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart9"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (3).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart10"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (2).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart11"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (4).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart12"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-biopyq"
                    role="tabpanel"
                    aria-labelledby="pills-biopyq-tab"
                    tabIndex="0"
                  >
                    Bio
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-phypyq"
                    role="tabpanel"
                    aria-labelledby="pills-phypyq-tab"
                    tabIndex="0"
                  >
                    Physics
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-engpyq"
                    role="tabpanel"
                    aria-labelledby="pills-engpyq-tab"
                    tabIndex="0"
                  >
                    English
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-chepyq"
                    role="tabpanel"
                    aria-labelledby="pills-chepyq-tab"
                    tabIndex="0"
                  >
                    Chemistry
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row row-two">
              <div className="col-md-6">
                <div className="box-main">
                  <ul className="ques-ul">
                    <li>
                      <div className="q-flex">
                        <span>Q.1</span>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisi elit.
                          Qui, illum dolor perferendis ?
                        </p>
                      </div>
                      <ul className="soln-ul">
                        <li><a href="#">Solutions</a></li>
                      </ul>
                    </li>
                  </ul>
                  <div className="d-none">
                    <p className="soln-desc">
                      Lorem ipsum dolor sit amet consectetur adipis elit.
                      Eligendi molestias corrupti dolore officiis fuga, tenetur
                      magnam ex ratione dolor blanditiis itaque asperiores! Est
                      ipsa ab impedit enim maiores cum commodi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <ul className="ques-ul">
                    <li>
                      <div className="q-flex">
                        <span>Q.1</span>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisi elit.
                          Qui, illum dolor perferendis ?
                        </p>
                      </div>
                      <ul className="soln-ul">
                        <li><a href="#">Solutions</a></li>
                      </ul>
                    </li>
                  </ul>
                  <div className="d-none">
                    <p className="soln-desc">
                      Lorem ipsum dolor sit amet consectetur adipis elit.
                      Eligendi molestias corrupti dolore officiis fuga, tenetur
                      magnam ex ratione dolor blanditiis itaque asperiores! Est
                      ipsa ab impedit enim maiores cum commodi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <ul className="ques-ul">
                    <li>
                      <div className="q-flex">
                        <span>Q.1</span>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisi elit.
                          Qui, illum dolor perferendis ?
                        </p>
                      </div>
                      <ul className="soln-ul">
                        <li><a href="#">Solutions</a></li>
                      </ul>
                    </li>
                  </ul>
                  <div className="d-none">
                    <p className="soln-desc">
                      Lorem ipsum dolor sit amet consectetur adipis elit.
                      Eligendi molestias corrupti dolore officiis fuga, tenetur
                      magnam ex ratione dolor blanditiis itaque asperiores! Est
                      ipsa ab impedit enim maiores cum commodi.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <ul className="ques-ul">
                    <li>
                      <div className="q-flex">
                        <span>Q.1</span>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipisi elit.
                          Qui, illum dolor perferendis ?
                        </p>
                      </div>
                      <ul className="soln-ul">
                        <li><a href="#">Solutions</a></li>
                      </ul>
                    </li>
                  </ul>
                  <div className="d-none">
                    <p className="soln-desc">
                      Lorem ipsum dolor sit amet consectetur adipis elit.
                      Eligendi molestias corrupti dolore officiis fuga, tenetur
                      magnam ex ratione dolor blanditiis itaque asperiores! Est
                      ipsa ab impedit enim maiores cum commodi.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  )
}

export default PyqComp