import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CouponCodeModal(props) {
    const [textCopied, setTextCopied] = useState(false)
    const {open, handleClose, coupons} = props

    const copyText = (str) => {
        setTextCopied(true)
        navigator.clipboard.writeText(str)

        setTimeout(() => {
            setTextCopied(false)
        }, 3000)
    }

  return (
    <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Coupon Codes</Modal.Title>
            {textCopied &&
                <div style={{position: "absolute", top: "0.5rem" ,width: "100%", display: "flex", justifyContent: "center"}}>
                    <Alert  key={"success"} variant={"success"}>
                            Copied to clipboard
                    </Alert>
                </div>
            }
        </Modal.Header>
        <Modal.Body style={{height: "400px",padding: "1rem", overflow: "auto"}}>

            <div className='d-flex flex-wrap'>
                {coupons.map((c_data, index) => {
                    return(
                        <div key={index} style={{cursor: textCopied ? "not-allowed" : "pointer"}} onClick={() => {
                            if(!textCopied){
                                copyText(c_data)
                            }
                        }}>
                            {c_data}
                            <i className="fa-solid fa-copy ms-2"></i>
                        </div>
                    )
                })}
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

export default CouponCodeModal