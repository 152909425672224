const initialState = {
    type:"",
    data:[],
    message:"",
    subjects: {type: "", data: []}
  }
  
  export function  adminReducer(state =initialState, action){
    let obj;
    let tmp = [];
     obj = {...state};
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "ADMIN_REQUEST":
            return  {...state, type: "request"}

        case "GET_SUBJECTS_REQUEST":
            return {...state, subjects: {...state.subjects, type: 'request'}}

        case "GET_SUBJECTS_SUCCESS":
            return {...state, subjects: {...state.subjects, type: 'success', data: action.data}}
        
        default:
            return state
    }
    return state;
  }
  