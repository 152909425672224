import React from 'react'

function BookmarkComp() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="cont mb-3 mt-3">
              <h1>Subject</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item item-hover math-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-mathsbookmarks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-mathsbookmarks"
                      type="button"
                      role="tab"
                      aria-controls="pills-mathsbookmarks"
                      aria-selected="true"
                    >
                      Maths
                    </button>
                  </li>
                  <li className="nav-item item-hover bio-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-biobookmarks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-biobookmarks"
                      type="button"
                      role="tab"
                      aria-controls="pills-biobookmarks"
                      aria-selected="false"
                    >
                      Bio
                    </button>
                  </li>
                  <li className="nav-item item-hover phy-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-phybookmarks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-phybookmarks"
                      type="button"
                      role="tab"
                      aria-controls="pills-phybookmarks"
                      aria-selected="false"
                    >
                      Physics
                    </button>
                  </li>
                  <li className="nav-item item-hover eng-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-engbookmarks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-engbookmarks"
                      type="button"
                      role="tab"
                      aria-controls="pills-engbookmarks"
                      aria-selected="false"
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item item-hover che-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-chebookmarks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-chebookmarks"
                      type="button"
                      role="tab"
                      aria-controls="pills-chebookmarks"
                      aria-selected="false"
                    >
                      Chemistry
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-mathsbookmarks"
                    role="tabpanel"
                    aria-labelledby="pills-mathsbookmarks-tab"
                    tabIndex="0"
                  >
                    <div className="row row-two">
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (1).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart13"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (3).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart14"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (2).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart15"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (4).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart16"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-biobookmarks"
                    role="tabpanel"
                    aria-labelledby="pills-biobookmarks-tab"
                    tabIndex="0"
                  >
                    Bio
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-phybookmarks"
                    role="tabpanel"
                    aria-labelledby="pills-phybookmarks-tab"
                    tabIndex="0"
                  >
                    Physics
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-engbookmarks"
                    role="tabpanel"
                    aria-labelledby="pills-engbookmarks-tab"
                    tabIndex="0"
                  >
                    English
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-chebookmarks"
                    role="tabpanel"
                    aria-labelledby="pills-chebookmarks-tab"
                    tabIndex="0"
                  >
                    Chemistry
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row row-two">
              <div className="col-md-6">
                <div className="box-main">
                  <div className="bknm-flex">
                    <div className="flex-name">
                      <div>
                        <img
                          src="assets/images/bookmark.png"
                          className="img-fluid"
                        />
                      </div>
                      <h5 className="bk-nm">Bookmark Name</h5>
                    </div>
                    <div>
                      <a href="javascript:void(0);" className="btn-del-icon"
                        ><i className="bx bx-edit-alt"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        id="sa-params"
                        className="btn-del-icon"
                        ><i className="bx bx-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                  <div className="dbtn-flex">
                    <h6>00:02:10</h6>
                    <div className="pl-btn">
                      <img
                        src="assets/images/pl-3d.png"
                        className="video-btn img-fluid"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                        data-bs-target="#myModal"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <div className="bknm-flex">
                    <div className="flex-name">
                      <div>
                        <img
                          src="assets/images/bookmark.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <h5 className="bk-nm">Bookmark Name</h5>
                    </div>
                    <div>
                      <a href="javascript:void(0);" className="btn-del-icon"
                        ><i className="bx bx-edit-alt"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        id="sa-params"
                        className="btn-del-icon"
                        ><i className="bx bx-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                  <div className="dbtn-flex">
                    <h6>00:02:10</h6>
                    <div className="pl-btn">
                      <img
                        src="assets/images/pl-3d.png"
                        className="video-btn img-fluid"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                        data-bs-target="#myModal"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <div className="bknm-flex">
                    <div className="flex-name">
                      <div>
                        <img
                          src="assets/images/bookmark.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <h5 className="bk-nm">Bookmark Name</h5>
                    </div>
                    <div>
                      <a href="javascript:void(0);" className="btn-del-icon"
                        ><i className="bx bx-edit-alt"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        id="sa-params"
                        className="btn-del-icon"
                        ><i className="bx bx-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                  <div className="dbtn-flex">
                    <h6>00:02:10</h6>
                    <div className="pl-btn">
                      <img
                        src="assets/images/pl-3d.png"
                        className="video-btn img-fluid"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                        data-bs-target="#myModal"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <div className="bknm-flex">
                    <div className="flex-name">
                      <div>
                        <img
                          src="assets/images/bookmark.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <h5 className="bk-nm">Bookmark Name</h5>
                    </div>
                    <div>
                      <a href="javascript:void(0);" className="btn-del-icon"
                        ><i className="bx bx-edit-alt"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        id="sa-params"
                        className="btn-del-icon"
                        ><i className="bx bx-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                  <div className="dbtn-flex">
                    <h6>00:02:10</h6>
                    <div className="pl-btn">
                      <img
                        src="assets/images/pl-3d.png"
                        className="video-btn img-fluid"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                        data-bs-target="#myModal"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <div className="bknm-flex">
                    <div className="flex-name">
                      <div>
                        <img
                          src="assets/images/bookmark.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <h5 className="bk-nm">Bookmark Name</h5>
                    </div>
                    <div>
                      <a href="javascript:void(0);" className="btn-del-icon"
                        ><i className="bx bx-edit-alt"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        id="sa-params"
                        className="btn-del-icon"
                        ><i className="bx bx-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                  <div className="dbtn-flex">
                    <h6>00:02:10</h6>
                    <div className="pl-btn">
                      <img
                        src="assets/images/pl-3d.png"
                        className="video-btn img-fluid"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                        data-bs-target="#myModal"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="box-main">
                  <div className="bknm-flex">
                    <div className="flex-name">
                      <div>
                        <img
                          src="assets/images/bookmark.png"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                      <h5 className="bk-nm">Bookmark Name</h5>
                    </div>
                    <div>
                      <a href="javascript:void(0);" className="btn-del-icon"
                        ><i className="bx bx-edit-alt"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        id="sa-params"
                        className="btn-del-icon"
                        ><i className="bx bx-trash-alt"></i
                      ></a>
                    </div>
                  </div>
                  <div className="dbtn-flex">
                    <h6>00:02:10</h6>
                    <div className="pl-btn">
                      <img
                        src="assets/images/pl-3d.png"
                        className="video-btn img-fluid"
                        data-bs-toggle="modal"
                        data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                        data-bs-target="#myModal"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default BookmarkComp