import axios from "axios"

export const getSubjects = (data) => {
    return async dispatch => {
      dispatch({
        type: "GET_SUBJECTS_REQUEST"
      })

      let allSubjects = await axios.get("/api/v3/s/get-subjects-list")

      if(allSubjects.data.status == "success"){
        dispatch({
          type: "GET_SUBJECTS_SUCCESS",
          data: allSubjects.data.response.data.subjects
        })
      }
    }
  }