import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { addCourse } from '../../../services/actions'

function SubsCard(props) {
    const {data, ind} = props
    const [availableAddOns, setAvailableAddOns] = useState([])

    const {boards} = props

    const navigate = useNavigate()

    const getAddOnsData = async () => {
      try{
          let getAddOns = await axios.get('/api/v3/s/get-addons/')
      
          if(getAddOns.data.status == 'error'){
            
          }else{
            setAvailableAddOns(getAddOns.data.response.data.plan)
          }
      }catch(e){
        console.log("error")
      }
      }

      useEffect(() => {
        getAddOnsData()
      }, [])
    
  return (
    <div className="col-md-3 col-sm-4">
                <div
                    className={`card gm-bx radius-10 border-0 border-info bg-color-${ind + 1}`}
                >
                    <div className="card-body card-bdy">
                    <div className="align-items-center mt-2">
                        <div className="d-flex king-img mb-3">
                        <img
                            src="/assets/images/admin/Vector.png"
                            className="img-fluid"
                            alt=""
                        />
                        <p className="mb-0 text-white ps-2">{data.name}</p>
                        </div>
                        <div className="d-flex th-justify mb-3">
                        <h4 className="my-1 text-info1">
                            {boards?.data?.filter((item) => item.id == boards.selectedBoardId)[0]?.standards?.filter((s_item) => s_item.id == boards.selectedStandardId)[0]?.name_slug.split('|')[0].slice(0, boards?.data?.filter((item) => item.id == boards.selectedBoardId)[0]?.standards?.filter((s_item) => s_item.id == boards.selectedStandardId)[0]?.name_slug.split('|')[0].indexOf("th"))}<sup className="sup-new">th</sup>
                        </h4>
                        <p className="mb-0 font-13 ps-2">{boards?.data?.filter((item) => item.id == boards.selectedBoardId)[0]?.name}</p>
                        </div>
                        <div className="rounded-circle text-white ms-auto mb-3">
                        <p>{data.name}</p>
                        <p>Plan Subscriptions</p>
                        </div>
                        <div className="text-center get-btn mb-2">
                        <button  onClick={() => {
                          props.addCourse( data)
                          navigate('/cart', {state: {data: {pageData: data, addOns:availableAddOns}}})
                        }}>Get</button>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
  )
}


const mapStateToProps = (state) => ({
    boards: state.boards
  });
  
  const mapDispatchToProps = {
    addCourse
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(SubsCard);