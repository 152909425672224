import React,{useEffect, useState} from "react";
import {Routes,Route,BrowserRouter, Navigate} from "react-router-dom";
import { useLocation} from 'react-router-dom'
import {AcceleratorBatchLayout, EduhelperLayout, LayoutOne, LayoutTwo} from "../layouts"
import Homepage from "../pages/homepage";
import ContactUs from "../pages/contact";
import AboutUs from "../pages/aboutUs";
import BecomeATeacher from "../pages/becomeATeacher";
import Subscription from "../pages/subscription";
import AdminDashboard from "../pages/admin";
import BuyPlan from "../pages/buyPlan";
import { connect } from "react-redux";

import { getBoardsWithStandards,setUserDataFromLocal,setCartDataFromLocal } from "../services/actions"; 
import PrivacyPolicy from "../pages/privacyPolicy";
import TermsConditions from "../pages/termsConditions";
import Cart from "../pages/cart";
import EduhelperLogin from "../pages/eduHelper/login";
import EduhelperDashboard from "../pages/eduHelper/dashboard";
import AcceleratorBatch from "../pages/AcceleratorBatch";


function _ScrollToTop(props) {
  
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children
}

const ScrollToTop =(_ScrollToTop)

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Layout {...rest} page={rest.page}>
      <Component {...rest} />
    </Layout>
  );
}

const RoutesObj = (props) => {
  const [pageLoadDone, setPageLoadDone] = useState(false)
  const {user,cart} = props

  const findUserData = async () => {
    let data = localStorage.getItem("user")
    data = JSON.parse(data)

    // let cartData = localStorage.getItem("cartData")
    // cartData = JSON.parse(cartData)

    if((data && (!user.login))){
      if((new Date().getTime() < data?.expire_time)){
        await props.setUserDataFromLocal(data)
        // if(cartData){
        //   await props.setCartDataFromLocal(data.token, cartData)
        // }
      }else{
        localStorage.removeItem("user")
      }
    }

    setPageLoadDone(true)
  }


  useEffect(() => {
    props.getBoardsWithStandards()
  }, [])

  useEffect(() => {
    findUserData()
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop>
        {pageLoadDone &&
          <Routes>
              {user.login ? 
              <>
              <Route exact path="/" element={<RouteWrapper exact component={Homepage} layout={LayoutOne} {...props}/>} />
              <Route exact path="/contact-us" element={<RouteWrapper exact component={ContactUs} layout={LayoutOne} {...props}/>} />
              <Route exact path="/about-us" element={<RouteWrapper exact component={AboutUs} layout={LayoutOne} {...props}/>} />
              <Route exact path="/become-a-teacher" element={<RouteWrapper exact component={BecomeATeacher} layout={LayoutOne} {...props}/>} />
              <Route exact path="/subscription" element={<RouteWrapper exact component={Subscription} layout={LayoutOne} {...props}/>} />
              <Route exact path="/buyPlan" element={<RouteWrapper exact component={BuyPlan} layout={LayoutOne} {...props}/>} />
              <Route exact path="/admin" element={<RouteWrapper exact component={AdminDashboard} layout={LayoutTwo} {...props}/>} />
              <Route exact path="/privacy-policy" element={<RouteWrapper exact component={PrivacyPolicy} layout={LayoutTwo} {...props}/>} />
              <Route exact path="/terms-conditions" element={<RouteWrapper exact component={TermsConditions} layout={LayoutTwo} {...props}/>} />
              <Route exact path="/cart" element={<RouteWrapper exact component={Cart} layout={LayoutOne} {...props}/>} />
              <Route path="*" element={<Navigate to="/" replace />} />
              </>
              :
              <>
                <Route exact path="/" element={<RouteWrapper exact component={Homepage} layout={LayoutOne} {...props}/>} />
                <Route exact path="/contact-us" element={<RouteWrapper exact component={ContactUs} layout={LayoutOne} {...props}/>} />
                <Route exact path="/about-us" element={<RouteWrapper exact component={AboutUs} layout={LayoutOne} {...props}/>} />
                <Route exact path="/become-a-teacher" element={<RouteWrapper exact component={BecomeATeacher} layout={LayoutOne} {...props}/>} />
                <Route exact path="/admin" element={<RouteWrapper exact component={AdminDashboard} layout={LayoutTwo} {...props}/>} />
                <Route exact path="/privacy-policy" element={<RouteWrapper exact component={PrivacyPolicy} layout={LayoutTwo} {...props}/>} />
              <Route exact path="/terms-conditions" element={<RouteWrapper exact component={TermsConditions} layout={LayoutTwo} {...props}/>} />
                <Route path="*" element={<Navigate to="/" replace />} />
              </>
              }
              <Route exact path="/accelerator-batch" element={<RouteWrapper exact component={AcceleratorBatch} layout={AcceleratorBatchLayout} {...props}/>} />
              <Route exact path="/eduhelper" element={<RouteWrapper exact component={EduhelperLogin} layout={LayoutTwo} {...props}/>} />
              <Route exact path="/eduhelper/dashboard" element={<RouteWrapper exact component={EduhelperDashboard} layout={EduhelperLayout} {...props}/>} />
          </Routes>
        }
        </ScrollToTop>
      </BrowserRouter>
    )
  }


  const mapStateToProps = (state) => ({
    user: state.user,
    cart: state.cart
  })

  const mapDispatchToProps = {
    getBoardsWithStandards,
    setUserDataFromLocal,
    setCartDataFromLocal
  }

export default connect(mapStateToProps, mapDispatchToProps)(RoutesObj)