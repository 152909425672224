const initialState = {
    type:"",
    data:{},
    pendingChatList: {type: "", data: []},
    ongoingChatList: {type: "", data: []},
    closedChatList: {type: "", data: []}
  }
  
  export function  eduhelperReducer(state =initialState, action){
    let obj;
    let tmp = [];
     obj = {...state};
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "EDUHELPER_REQUEST":
            return  {...state, type: "request"}
        case "EDUHELPER_LOGIN_SUCCESS":
            return  {...state, type: "success", data: action.data}
        case "ALL_CHATS_REQUEST": 
            return {...state, pendingChatList: {...state.pendingChatList, type: "request"}, ongoingChatList: {...state.ongoingChatList, type: "request"},closedChatList: {...state.closedChatList, type: "request"}}
        case "PENDING_CHAT_SUCCESS":
            return  {...state, type: "success", pendingChatList: {type: "success", data: action.data}}
        case "ONGOING_CHAT_SUCCESS":
            return  {...state, type: "success", ongoingChatList: {type: "success", data: action.data}}
        case "CLOSED_CHAT_SUCCESS":
            return  {...state, type: "success", closedChatList: {type: "success", data: action.data}}
        default:
            return state
    }
    return state;
  }
  