const initialState = {
    type:"",
    data:[],
    message:"",
    selectedBoardId: "",
    selectedStandardId: ""
  }
  
  export function  boardReducer(state =initialState, action){
    let obj;
    let tmp = [];
     obj = {...state};
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "GET_BOARDS_REQUEST":
            return  {...state, type: "request"}
        case "GET_BOARDS_SUCCESS":
            return  {...state, type: "success", data: action.data}
        case "GET_BOARDS_ERROR":
            return  {...state, type: "error", message: action.message}
        case "SET_BOARD_ID_SUCCESS":
            return {...state, selectedBoardId: action.id}
        case "SET_STANDARD_ID_SUCCESS":
            return {...state, selectedStandardId: action.id}
  
        default:
            return state
    }
    return state;
  }
  