import React, { useEffect, useState } from 'react'
import DashboardComp from '../../components/admin/dashboard'
import StudyComp from '../../components/admin/study'
import BatchesComp from '../../components/admin/batches'
import AskAAdoubt from '../../components/admin/askADoubt'
import TestComponent from '../../components/admin/test'
import EBookComp from '../../components/admin/eBook'
import BookmarkComp from '../../components/admin/bookmark'
import PyqComp from '../../components/admin/pyq'
import MyProgress from '../../components/admin/myProgress'
import StoreComp from '../../components/admin/store'
import Champions from '../../components/admin/champions'
import FeedsComp from '../../components/admin/feeds'
import { connect } from 'react-redux'

import { getSubjects } from '../../services/actions'

function AdminDashboard(props) {
    const [activeComponent, setActiveComponent] = useState('dashboard')

    useEffect(() => {
        // Calling the reducer functions
        props.getSubjects()
    }, [])


    return (
        <div className="wrapper">
            <div className="sidebar-wrapper" data-simplebar="true">
                <div className="sidebar-header">
                    <div>
                        <img
                            src="/assets/images/admin/first-logo.png"
                            className="logo-icon"
                            alt="logo icon"
                        />
                    </div>
                    <div className="toggle-icon ms-auto">
                        <i className="bx bx-arrow-back"></i>
                    </div>
                </div>
                <ul className="metismenu" id="menu">
                    {/* <li className='mm-active'> */}
                    <li className={activeComponent == 'dashboard' ? 'mm-active' : ''} onClick={() => setActiveComponent('dashboard')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/dashboardicon.png" alt="" />
                            </div>
                            <div className="menu-title">Dashboard</div>
                        </span>
                    </li>

                    <li className={activeComponent == 'study' ? 'mm-active' : ''} onClick={() => setActiveComponent('study')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/studyicon.png" alt="" />
                            </div>
                            <div className="menu-title">Study</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'batches' ? 'mm-active' : ''} onClick={() => setActiveComponent('batches')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/batchesicon.png" alt="" />
                            </div>
                            <div className="menu-title">Batches</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'doubt' ? 'mm-active' : ''} onClick={() => setActiveComponent('doubt')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/doubticon.png" alt="" />
                            </div>
                            <div className="menu-title">Ask a doubt</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'test' ? 'mm-active' : ''} onClick={() => setActiveComponent('test')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/testicon.png" alt="" />
                            </div>
                            <div className="menu-title">Test</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'eBook' ? 'mm-active' : ''} onClick={() => setActiveComponent('eBook')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/ebookicon.png" alt="" />
                            </div>
                            <div className="menu-title">Ebook</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'bookmark' ? 'mm-active' : ''} onClick={() => setActiveComponent('bookmark')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/bookmarkicon.png" alt="" />
                            </div>
                            <div className="menu-title">Bookmark</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'pyq' ? 'mm-active' : ''} onClick={() => setActiveComponent('pyq')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/pyqicon.png" alt="" />
                            </div>
                            <div className="menu-title">PYQ</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'myprogress' ? 'mm-active' : ''} onClick={() => setActiveComponent('myprogress')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/progressicon.png" alt="" />
                            </div>
                            <div className="menu-title">My Progress</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'store' ? 'mm-active' : ''} onClick={() => setActiveComponent('store')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/carticon.png" alt="" />
                            </div>
                            <div className="menu-title">EduAid Store</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'champions' ? 'mm-active' : ''} onClick={() => setActiveComponent('champions')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/champicon.png" alt="" />
                            </div>
                            <div className="menu-title">EduAid Champions</div>
                        </span>
                    </li>
                    <li className={activeComponent == 'feeds' ? 'mm-active' : ''} onClick={() => setActiveComponent('feeds')}>
                        <span>
                            <div className="parent-icon">
                                <img src="/assets/images/admin/feedicon.png" alt="" />
                            </div>
                            <div className="menu-title">Feeds</div>
                        </span>
                    </li>
                </ul>

            </div>
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand">
                        <div className="mobile-toggle-menu"><i className="bx bx-menu"></i></div>
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle nav-button"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Choose Board
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle nav-button"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                Study Material
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </li>
                            </ul>
                        </div>
                        <div className="dropdown">
                            <button
                                className="btn btn-secondary dropdown-toggle nav-button"
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img
                                    src="/assets/images/admin/2993668_brand_brands_google_logo_logos_icon.png"
                                    width="24"
                                    alt=""
                                />
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Action</a></li>
                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                <li>
                                    <a className="dropdown-item" href="#">Something else here</a>
                                </li>
                            </ul>
                        </div>
                        <div className="top-menu ms-auto">
                            <ul className="navbar-nav align-items-center gap-1">
                                <li className="nav-item dropdown dropdown-large">
                                    <a className="nav-link position-relative" href="#" role="button">
                                        <i className="bx bx-search-alt-2"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <div className="header-message-list"></div>
                                    </div>
                                </li>
                                <li className="nav-item dropdown dropdown-large">
                                    <a
                                        className="nav-link dropdown-toggle dropdown-toggle-nocaret position-relative"
                                        href="#"
                                        data-bs-toggle="dropdown"
                                    ><span className="alert-count">7</span>
                                        <i className="bx bx-bell"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a href="javascript:;">
                                            <div className="msg-header">
                                                <p className="msg-header-title">Notifications</p>
                                                <p className="msg-header-badge">8 New</p>
                                            </div>
                                        </a>
                                        <div className="header-notifications-list">
                                            <a className="dropdown-item" href="javascript:;">
                                                <div className="d-flex align-items-center">
                                                    <div className="notify bg-light-danger text-danger">
                                                        dc
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="msg-name">
                                                            New Orders
                                                            <span className="msg-time float-end">2 min ago</span>
                                                        </h6>
                                                        <p className="msg-info">You have recived new orders</p>
                                                    </div>
                                                </div>
                                            </a>

                                            <a className="dropdown-item" href="javascript:;">
                                                <div className="d-flex align-items-center">
                                                    <div className="notify bg-light-success text-success">
                                                        <img
                                                            src="/assets/images/admin/app/outlook.png"
                                                            width="25"
                                                            alt="user avatar"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="msg-name">
                                                            Account Created<span className="msg-time float-end"
                                                            >28 min ago</span>
                                                        </h6>
                                                        <p className="msg-info">
                                                            Successfully created new email
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item" href="javascript:;">
                                                <div className="d-flex align-items-center">
                                                    <div className="notify bg-light-info text-info">Ss</div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="msg-name">
                                                            New Product Approved
                                                            <span className="msg-time float-end">2 hrs ago</span>
                                                        </h6>
                                                        <p className="msg-info">
                                                            Your new product has approved
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                            <a className="dropdown-item" href="javascript:;">
                                                <div className="d-flex align-items-center">
                                                    <div className="notify bg-light-success text-success">
                                                        <i className="bx bx-check-square"></i>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6 className="msg-name">
                                                            Your item is shipped
                                                            <span className="msg-time float-end">5 hrs ago</span>
                                                        </h6>
                                                        <p className="msg-info">
                                                            Successfully shipped your item
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <a href="javascript:;">
                                            <div className="text-center msg-footer">
                                                <button className="btn btn-primary w-100">
                                                    View All Notifications
                                                </button>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="user-box dropdown px-3">
                            <a
                                className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img
                                    src="/assets/images/admin/loginavtar.png"
                                    className="user-img"
                                    alt="user avatar"
                                />
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <a
                                        className="dropdown-item d-flex align-items-center"
                                        href="javascript:;"
                                    ><i className="bx bx-user fs-5"></i><span>Profile</span></a>
                                </li>

                                <li>
                                    <div className="dropdown-divider mb-0"></div>
                                </li>
                                <li>
                                    <a
                                        className="dropdown-item d-flex align-items-center"
                                        href="javascript:;"
                                    ><i className="bx bx-log-out-circle"></i><span>Logout</span></a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

            {activeComponent == 'dashboard' &&
                <DashboardComp />
            }
            {activeComponent == 'study' &&
                <StudyComp />
            }
            {activeComponent == 'batches' &&
                <BatchesComp />
            }
            {activeComponent == 'doubt' &&
                <AskAAdoubt />
            }
            {activeComponent == "test" &&
                <TestComponent />
            }
            {activeComponent == "eBook" &&
                <EBookComp />
            }
            {activeComponent == "bookmark" &&
                <BookmarkComp />
            }
            {activeComponent == "pyq" &&
                <PyqComp />
            }
            {activeComponent == "myprogress" &&
                <MyProgress />
            }
            {activeComponent == "store" &&
                <StoreComp />
            }
            {activeComponent == "champions" &&
                <Champions />
            }
            {activeComponent == "feeds" &&
                <FeedsComp />
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
    getSubjects
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
