import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='container py-5'>
        <div class="col-lg-12 col-sm-12 col-xs-12" style={{marginTop: "5%;margin-bottom: 5%;text-align:justify"}}>


        <h2>Privacy Policy</h2>
        <br/>
        Effective date: 1st January, 2020<br/><br/>
        SIQES EDUAID LLP ("us", "we", or "our") operates the https://www.eduaid.co.in website and the EduAid mobile
        application (hereinafter referred to as the "Service").
        This page informs you of our policies regarding the collection, use and disclosure of personal data when you use
        our Service and the choices you have associated with that data.
        We use your data to provide and improve the Service. By using the Service, you agree to the collection and use
        of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used
        in this Privacy Policy have the same meanings as in our Terms and Conditions.
        <br/><br/>
        <h5>Definitions</h5><br/>
        <br/>
        <b>Service</b>:
        Service means the https://www.eduaid.co.in website and the EduAid mobile application operated by SIQES EDUAID
        LLP.
        <br/>
        <b>Personal Data</b>
        Personal Data means data about a living individual who can be identified from those data (or from those and
        other information either in our possession or likely to come into our possession).

        <br/><b>Usage Data</b>
        Usage Data is data collected automatically either generated by the use of the Service or from the Service
        infrastructure itself (for example, the duration of a page visit).

        <br/><b>Cookies</b>
        Cookies are small files stored on your device (computer or mobile device).

        <br/><b>Information Collection and Use</b>
        We collect several different types of information for various purposes to provide and improve our Service to
        you.

        <br/>Types of Data Collected<br/>
        Personal Data
        While using our Service, we may ask you to provide us with certain personally identifiable information that can
        be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is
        not limited to:
        Email address
        First name and last name
        Phone number
        Address, State, Province, ZIP/Postal code, City
        Cookies and Usage Data
        We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other
        information that may be of interest to you. You may opt out of receiving any, or all, of these communications
        from us by following the unsubscribe link or the instructions provided in any email we send.
        Usage Data
        We may also collect information that your browser sends whenever you visit our Service or when you access the
        Service by or through a mobile device ("Usage Data").
        This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address),
        browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the
        time spent on those pages, unique device identifiers and other diagnostic data.
        When you access the Service with a mobile device, this Usage Data may include information such as the type of
        mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating
        system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.
        Location Data
        We may use and store information about your location if you give us permission to do so ("Location Data"). We
        use this data to provide features of our Service, to improve and customise our Service.
        You can enable or disable location services when you use our Service at any time by way of your device settings.
        Tracking Cookies Data
        We use cookies and similar tracking technologies to track the activity on our Service and we hold certain
        information.
        Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent
        to your browser from a website and stored on your device. Other tracking technologies are also used such as
        beacons, tags and scripts to collect and track information and to improve and analyse our Service.
        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you
        do not accept cookies, you may not be able to use some portions of our Service.
        Examples of Cookies we use:
        Session Cookies. We use Session Cookies to operate our Service.
        Preference Cookies. We use Preference Cookies to remember your preferences and various settings.
        Security Cookies. We use Security Cookies for security purposes.


        <br/>
        <b>Use of Data</b>
        SIQES EDUAID LLP uses the collected data for various purposes:<br/>
        To provide and maintain our Service<br/>
        To notify you about changes to our Service<br/>
        To allow you to participate in interactive features of our Service when you choose to do so<br/>
        To provide customer support<br/>
        To gather analysis or valuable information so that we can improve our Service<br/>
        To monitor the usage of our Service<br/>
        To detect, prevent and address technical issues<br/>
        To provide you with news, special offers and general information about other goods, services and events which we
        offer that are similar to those that you have already purchased or enquired about unless you have opted not to
        receive such information<br/>
        <br/>
        <b>Transfer of Data</b>
        Your information, including Personal Data, may be transferred to — and maintained on — computers located outside
        of your state, province, country or other governmental jurisdiction where the data protection laws may differ
        from those of your jurisdiction.
        If you are located outside India and choose to provide information to us, please note that we transfer the data,
        including Personal Data, to India and process it there.
        Your consent to this Privacy Policy followed by your submission of such information represents your agreement to
        that transfer.<br/>
        SIQES EDUAID LLP will take all the steps reasonably necessary to ensure that your data is treated securely and
        in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation
        or a country unless there are adequate controls in place including the security of your data and other personal
        information.
        <br/>
        <b>Disclosure of Data</b>
        Disclosure for Law Enforcement<br/>
        Under certain circumstances, SIQES EDUAID LLP may be required to disclose your Personal Data if required to do
        so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
        Legal Requirements
        <br/><br/>
        SIQES EDUAID LLP may disclose your Personal Data in the good faith belief that such action is necessary
        to:<br/><br/>
        To comply with a legal obligation<br/>
        To protect and defend the rights or property of SIQES EDUAID LLP<br/>
        To prevent or investigate possible wrongdoing in connection with the Service<br/>
        To protect the personal safety of users of the Service or the public<br/>
        To protect against legal liability<br/><br/>

        <b>Security of Data</b><br/>

        The security of your data is important to us but remember that no method of transmission over the Internet or
        method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect
        your Personal Data, we cannot guarantee its absolute security.<br/><br/>

        <b>Service Providers</b><br/>
        We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the
        Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
        These third parties have access to your Personal Data only to perform these tasks on our behalf and are
        obligated not to disclose or use it for any other purpose.<br/><br/>

        <b>Analytics</b><br/>

        We may use third-party Service Providers to monitor and analyse the use of our Service.

        <b>Payments</b> <br/>
        We may provide paid products and/or services within the Service. In that case, we use third-party services for
        payment processing (e.g. payment processors).<br/>

        We will not store or collect your payment card details. That information is provided directly to our third-party
        payment processors whose use of your personal information is governed by their Privacy Policy. These payment
        processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a
        joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure
        the secure handling of payment information.<br/>

        
        
        
        
        
        <b>Links to Other Sites</b>
        Our Service may contain links to other sites that are not operated by us. If you click a third party link, you
        will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site
        you visit.
        We have no control over and assume no responsibility for the content, privacy policies or practices of any third
        party sites or services.
        <br/>

        <b>Changes to This Privacy Policy</b><br/>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy
        Policy on this page.<br/>

        We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective
        and update the "effective date" at the top of this Privacy Policy.<br/>

        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
        effective when they are posted on this page.
        <br/>
        Contact Us
        If you have any questions about this Privacy Policy, please contact us: <br/><br/>
        By email: info@eduaid.co.in <br/>
        By visiting this page on our website: https://eduaid.co.in <br/>
        By phone number: +919477994779 <br/>
        By mail: 2nd floor, divine plaza, G.S. Road. Dispur, Guwahati 781006, Assam
        </div>
    </div>
  )
}

export default PrivacyPolicy