import React, { useState } from 'react'
import BecomeATeacherModal from '../../components/modals/becomeATeacher'
import { ToastContainer, toast } from 'react-toastify'
import { connect } from 'react-redux'


function BecomeATeacher(props) {
    const {user} = props
    const [open, setOpen] = useState(false)

    const handleClose = () => setOpen(false)

    return (
        <>
            <ToastContainer />
            <section>
                <div className="bigimage-become">
                    <div className="bgoverlay-become bg-bread">
                        <nav className="nav-position" style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="./index.html">Home</a>
                                </li>
                                <li className="breadcrumb-item text-white active" aria-current="page">
                                    Become a teacher
                                </li>
                            </ol>
                        </nav>
                        <h3>Become a teacher</h3>
                    </div>
                </div>
            </section>
            <section className="tut-margn">
                <div className="container">
                    <div className="cont-border-radius-demo online-pdg">
                        <div className="row text-center align-items-center">
                            <div className="col-md-2">
                                <div className="logo-becm logo-ht">
                                    <img src="assets/images/becomelogo.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                            <div className="col-md-7">
                                <div className="appl--box">
                                    <h6>Join EduAid's esteemed team of educators</h6>
                                    <p>and ignite a world of learning opportunities for students!</p>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="logo-becm per-img">
                                    <img src="assets/images/profesr.png" className="img-fluid" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="apply-mr">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <button className="btn btn-danger buy-now-btn" href="javascript:void(0)" onClick={() => {
                                    setOpen(true)
                                }}>Apply Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="teachaid">
                                <h4>Welcome to EduAid Learning</h4>
                                <h5>Empowering Minds, Enriching Futures!</h5>
                                <p>
                                At EduAid Learning, we believe in the power of education and the incredible potential of every student. We are dedicated to aspiring teachers like you, who are passionate about inspiring a love for learning and creating meaningful educational experiences.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="brand-box">
                                <div className="br-img">
                                    <img src="assets/images/diamond.png" alt="" />
                                </div>
                                <h6>Professional Growth</h6>
                                <p>
                                We foster growth by facilitating collaboration among educators and experts, leading to enhanced expertise and professional development.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="brand-box">
                                <div className="br-img">
                                    <img src="assets/images/ytb.png" alt="" />
                                </div>
                                <h6>Personalized teaching tools</h6>
                                <p>
                                Our personalized teaching tools empower educators to tailor instruction based on students' unique learning styles, ensuring impactful and effective teaching approaches.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="brand-box">
                                <div className="br-img">
                                    <img src="assets/images/bar.png" alt="" />
                                </div>
                                <h6>Empowering Futures</h6>
                                <p>
                                EduAid empowers teachers to uplift lives, making a lasting impact in underserved areas through quality education.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="trial-sec">
                <div className="bigimage-btm-become">
                    <div className="bgoverlay-btm-become expert-box">
                        <h3>Join EduAid of Expert Teacher</h3>
                        <p>
                            Teach individuals and teams from startups to more than one-third of
                            the Fortune 100 online or at our campuses around the world.
                        </p>
                        {!user.login &&
                            <button className="btn btn-danger buy-now-btn reg-bcome-btn" onClick={() => {
                                document.getElementById(`login-btn`).click()
                            }} >Register Now</button>
                        }
                    </div>
                </div>
            </section>
            {open &&
                <BecomeATeacherModal toast={toast} open={open} handleClose={handleClose}/>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
  });
  
  const mapDispatchToProps = {
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(BecomeATeacher);
  